@import '../../../marketplace.css';

:root {
  --ConvenienceWidth: 100px;
  --ConvenienceMarginRight: 41px;
}

.root {
}

.pageRoot {
  display: flex;
  padding-bottom: 90px;
  @media (--viewportLarge) {
    padding-bottom: 0;
  }
}

.sectionImages {
  position: relative;
  overflow: hidden;
}

.loadingText {
  margin: 24px;
}

.errorText {
  color: var(--failColor);
  margin: 24px;
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    padding: 36px 36px 0 36px;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  position: relative; /* allow positioning own listing action bar */
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */

  /* Image carousel can be opened from the image, therefore we should show a pointer */
  cursor: pointer;

  @media (--viewportMedium) {
    padding-bottom: 0; /* No fixed aspect on desktop layouts */
  }
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  transition: var(--transitionStyleButton);

  @media (--viewportMedium) {
    position: static;
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: calc(0.5 * 100vw);
    max-height: 65vh;
    object-fit: cover;
    border-radius: var(--borderRadius);
  }
}

.contentContainer {
  @media (--viewportLarge) {
    max-width: 1056px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    position: relative;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 0 auto 117px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.mainContent {
  flex-basis: 100%;
  margin-bottom: 23px;

  @media (--viewportMedium) {
    margin-top: 50px;
    margin-bottom: 51px;
    flex-shrink: 0;
  }

  @media (--viewportLarge) {
    margin-top: 50px;
    margin-bottom: 0px;
    /* contentContainer's full width - (bookingColumn + two paddings + margin + border) */
    max-width: calc(100% - 433px);
    flex-basis: calc(100% - 433px);
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.sectionHeading {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--matterColorNegative);

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.desktopPriceContainer {
  display: none;

  @media (--viewportLarge) {
    /* Layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    margin: 0 48px 0 0;
    padding: 0;
  }
}

.rentalAgreementText {
  font-size: 14px;
  margin-left: 20px;
}

.desktopPriceValue {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 6px;
  }
}

.desktopPerUnit {
  /* Font */
  @apply --marketplaceH5FontStyles;
  color: var(--marketplaceColorDark);

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.logoAndPriceWrapper {
  display: flex;
  flex-direction: column;
}

.bottomActions {
  position: absolute;
  display: flex;
  flex-wrap: wrap;

  /* Position and dimensions */
  position: absolute;
  bottom: 19px;
  right: 24px;
  margin: 0;
  margin-right: 20px;

  @media (--viewportMedium) {
  }
}

.viewPhotos {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  margin-left: 10px;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (--viewportMedium) {
    margin: 0;
    margin-left: 10px;
  }
}

.logo {
  width: 15px;
  height: 12px;
  margin-right: 10px;
}

.shareButton {
  @apply --marketplaceTinyFontStyles;
  font-weight: var(--fontWeightMedium);

  /* Position and dimensions */
  margin: 0;
  padding: 6px 13px 8px 13px;

  /* Colors */
  background-color: var(--matterColorLight);

  /* Borders */
  border: none;
  border-radius: var(--borderRadius);

  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    background-color: var(--marketplaceColor);
    color: var(--matterColorLight);
  }

  @media (min-width: 1500px) {
    bottom: 19px;
    margin: 0;
    left: 24px;
    right: unset;
  }
}

.goLogo {
  height: 54px;
  width: auto;
  max-width: 125px;
  margin-right: 25px;
}

.carouselModalScrollLayer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(1, 1, 1, 0.92);
}

.carouselModalContainer {
  width: 100%;
  height: 100%;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
}

.authorName {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 22px;
  }
}

.listingName {
  font-size: 24px;
  line-height: 29px;
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);

  @media (--viewportLarge) {
    font-size: 48px;
    line-height: 57px;
  }
}

.section {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--matterColorNegative);

  @media (--viewportMedium) {
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.sectionDescription {
  padding: 30px;
  display: flex;
  flex-direction: column;
  border-bottom: solid 1px var(--matterColorNegative);
}

.sectionTitle {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 24px;
    line-height: 29px;
  }
}

.description {
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-top: 10px;

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 26px;
    margin-top: 20px;
  }
}

.conveniences {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px
    calc(
      calc(100% - var(--ConvenienceWidth) - var(--ConvenienceWidth) - var(--ConvenienceMarginRight)) /
        2
    )
    0;

  @media (--viewportLarge) {
    padding: 46px 23px 18px;
  }
}

.convenience {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 38px;
  margin-top: 0px;

  @media (--viewportLarge) {
    margin-right: 38px;
    width: calc(50% - 42px);
  }
}

.convenience:nth-child(2n + 2) {
  margin-right: 0px;
}

.convenience:nth-child(n + 3) {
  margin-top: 40px;
}

.convenience:last-child {
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.convenienceLabel {
  font-size: 16px;
  line-break: 19px;
  font-weight: var(--fontWeightSemiBold);
  text-align: center;
  color: var(--marketplaceColor);
  margin-top: 15px;
}

.sectionInnovative {
  background-color: rgba(0, 163, 173, 0.05);
  padding-left: 30px;
  padding-right: 30px;
}

.features {
  margin-top: 10px;

  @media (--viewportLarge) {
    margin-top: 16px;
  }
}

.rule {
  margin-top: 10px;
  font-size: 16px;
  line-height: 19px;
  font-weight: var(--fontWeightMedium);
  /* color: var(--marketplaceColorDark); */

  @media (--viewportLarge) {
    font-size: 20px;
    line-height: 24px;
  }
}

.map {
  /* Dimensions: Map takes all available space from viewport (excludes action button and section title) */
  height: calc(100vh - 193px);
  width: 100%;

  /* Static map: dimensions are 640px */
  max-width: 640px;
  max-height: 640px;
  background-color: #eee;
  padding: 0;
  border: 0;
  cursor: pointer;
  margin-top: 10px;

  @media (--viewportMedium) {
    height: 75vh;
  }

  @media (--viewportLarge) {
    height: 417px;
    //margin-top: 35px;
  }
}

.insuranceTitle {
  white-space: pre;

  @media (--viewportMedium) {
    white-space: unset;
  }
}

.insuranceLogo {
  width: 97px;
  height: 97px;
}

.insuranceContentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.insurances {
  white-space: pre;
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 18px;
    line-height: 24px;
  }
}

.fullInsurance {
  font-size: 16px;
  line-height: 19px;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  margin-top: 20px;
}

.sectionHostContentWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;

  @media (--viewportLarge) {
    margin-top: 20px;
  }
}

.sectionHostContent {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.avatarWrapper {
  width: 60px;
}

.sectionHostContent > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.avgVal {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-left: 5px;

  @media (--viewportLarge) {
    font-size: 14px;
    line-height: 17px;
  }
}

.createAt {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);
  margin-top: 5px;

  @media (--viewportLarge) {
    font-size: 14px;
    line-height: 17px;
  }
}

.responseTime {
  font-size: 12px;
  line-height: 14px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  margin-top: 5px;

  @media (--viewportLarge) {
    font-size: 14px;
    line-height: 17px;
  }
}

.bookingPanel {
  @media (--viewportLarge) {
    display: block;
    margin-top: 95px;
    margin-left: 60px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: var(--matterColorNegative);

    padding-left: 60px;

    /* The calendar needs 312px */
    flex-basis: 312px;
    flex-shrink: 0;
  }
}

/* Styling the avatar Image under the listing cover picture */
.sectionAvatar {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -31px;

  /* Rendering context to the same lavel as listing image */
  position: relative;

  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportLarge) {
    position: absolute;
    top: -49px;
    margin-left: 0;
    margin-top: 0;
  }
}
.avatarMobile {
  display: flex;

  @media (--viewportMedium) {
    display: none; /* Hide the medium avatar from the bigger screens */
  }
}

.avatarDesktop {
  display: none; /* Hide the large avatar from the smaller screens */

  @media (--viewportMedium) {
    display: flex;
  }
}

.review {
  display: flex;
  flex-direction: row;
}

.reviewItem {
  margin-bottom: 20px;

  @media (--viewportLarge) {
    margin-bottom: 30px;
  }
}

.reviewItem:last-child {
  margin-bottom: 0;
}

.reviewInfo {
  margin-left: 10px;
}

.author {
  font-size: 12px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  margin-top: 6px;

  @media (--viewportMedium) {
    font-size: 14px;
  }
}

.createdAt {
  margin-left: 5px;
}

.reviewContent {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColorDark);

  @media (--viewportLarge) {
    font-size: 17px;
  }
}

.section:last-child {
  border: none;
}

.toggleShowReview {
  font-size: 14px;
  line-height: 24px;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);
  cursor: pointer;
}

.links {
  @apply --marketplaceH4FontStyles;
  margin-top: 13px;

  @media (--viewportMedium) {
    margin: 16px 0 0 0;
  }
}

.linkSeparator {
  margin: 0 10px;
  color: var(--marketplaceColor);
}

.contact {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.link {
  white-space: nowrap;
}

.enquiryModalContent {
  flex-grow: 1;
  display: flex;
}

.enquiryForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.enquirySubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}

.contactWrapper {
  display: none;

  @media (--viewportMedium) {
    display: inline;
  }
}

.separator {
  margin: 0 6px;
}

.contactLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH4FontStyles;
  margin: 0;
}

.averageContainer {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}

.star {
  fill: var(--marketplaceColor);
  width: 14px;
  vertical-align: top;
  margin-right: 5px;
}

.doneTrips {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorDark);
  margin-left: 5px;
}

.wrapperLabelDisinfected {
  position: relative;
  display: inline-block;
  left: 15px;
  font-size: 16px;

  & .numberDays {
    background-color: var(--marketplaceSecondaryColor);
    color: var(--marketplaceColorDark);
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: -3px;

    & .number {
      font-weight: 900;
      line-height: 18px;
    }
    & .day {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 10px;
      line-height: 12px;
    }
  }
  & .textLabel {
    color: var(--marketplaceSecondaryColor);
    display: inline-block;
    font-weight: 900;
    line-height: 22px;
    text-transform: uppercase;
    background-color: #00a3ad;
    border-radius: 5px;
    padding: 8px 17px;
    z-index: 2;
    padding-left: 30px;
    margin-left: 15px;
  }
}
