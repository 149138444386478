@import '../../marketplace.css';

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--matterColorBright);
  padding: 30px 0px;
}

.filtersWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filterWrapper {
  flex-basis: 52%;
  position: relative;
}

.filterWrapper:nth-child(2n + 1) {
  flex-basis: 44%;
  position: relative;
}

.filterWrapperFullWidth {
  flex-basis: 100%;
  position: relative;
}

.priceFilter {
  height: 100%;
}

.footer {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 50%;
  padding: 20px 24px 20px 24px;
  background: #ffffff;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  @media (--viewportLarge) {
    max-width: 62.5%;
    padding: 20px 40px 20px 40px;
  }
}

.resetAllButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.cancelButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--matterColor);
  }
}

.applyButton {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  margin: 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.applyButton:disabled {
  @apply --submitButtonDisabled;
}

.submitWarning {
  @apply --submitWarning;
  margin-top: 0;
  text-align: right;
}

.clearbutton {
  position: absolute;
  bottom: 0;
  right: 10px;
}

.fullHeight {
  height: 100%;
}
