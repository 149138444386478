@import '../../marketplace.css';

.pageTitle {
  text-align: center;
}

.staticPageWrapper {
  /* width: calc(100% - 48px); */
  width: 100%;
  /* max-width: 1056px; */
  /* margin: 24px auto; */

  @media (--viewportMedium) {
    /* width: calc(100% - 72px); */
    /* margin: 72px auto; */
  }
}

.coverImageContaienr{
  width: 100%;
  background-color: var(--marketplaceColor);
}

.coverImage {
  max-width: 100%;
  width: calc(100% - 48px);
  display: block;
  margin: 24px auto;

  @media (--viewportMedium) {
    width: calc(100% - 15vw);
    margin: 70px 7.5vw;
  }
  @media (--viewportMLarge){
    width: 1100px;
    margin: 82px auto 102px;
  }
}

.safeFirstImage{
  width: 153px;
  height: 136px;
  display: block;
  margin: 60px auto 30px;

  @media (--viewportMedium) {
    margin-top: 0px;
  }
}

.bottomCoverImage {
  width: 100%;
  height: calc(100vw / 4.1);
  /* border-radius: 4px; */
  object-fit: cover;
  /* margin: 32px 0 40px; */
}


.infinityImage {
  width: 100%;
  /* border-radius: 4px; */
  object-fit: cover;
  /* margin: 32px 0 40px; */
}

.contentWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 1056px;
  align-self: center;
  margin: 24px auto;
  margin-left: 20px;
  @media (--viewportMedium) {
    flex-wrap: nowrap;
    margin: 72px auto;
    margin-left: 20px;
  }
  @media screen and (min-width: 1000px) {
    margin: 72px auto;
  }
}

.bottomWrapper {
  max-width: 100%;
  position: relative;
}

.sloganWrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: auto;
  max-width: 100%;
  align-self: center;
  margin: 40px auto;
  margin-left: 20px;
  margin-right: 20px;

  @media (--viewportMedium) {
    flex-wrap: nowrap;
    margin-left: 7.5vw;
    margin-right: 7.5vw;
  }
  @media (--viewportMLarge) {
    margin: 40px auto;
    max-width: 1100px;
  }

}


.contentSide {
  /* font-style: italic; */
  text-align: center;

  @media (--viewportMedium) {
    width: 193px;
    margin-right: 103px;
    margin-top: 8px;
    text-align: start;
  }
}

.contentMain {
  width: 90%;
  text-align: center;
  @media (--viewportMedium) {
    max-width: 650px;
    text-align: start;
  }
}

.sloganMain {
  color: var(--matterColorLight);
  width: 100%;
  text-align: left;
  @media (--viewportMedium) {
    text-align: start;
    margin-top: 26px;
  }
}

.firstSlogan{
  margin-top: 0;
  display: flex;
  flex-direction: column;
  @media (--viewportMedium){
    flex-direction: row;
  }
}

.subtitle {
  color:var(--marketplaceColor);
  @apply --marketplaceH3FontStyles;
  margin-top: 48px;
  margin-bottom: 24px;
}

.logo {
  width: 120px;
  height: 100px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
  @media (--viewportMedium) {
    display: inline-block;
    margin-bottom: 20px;
  }
}

.slogan {
  color: var(--marketplaceColorDark);
  position: absolute;
  margin-top: 20px;
  margin-left: 170px;
  font-size: 16px;
  @media (--viewportMedium) {
    font-size: 40px;
    margin-left: 420px;
    margin-top: 30px;
    width: 276px;
    height: 65px;
  }

  @media screen and (min-width: 1000px) {
    margin-top: 20px;
    margin-left: 120px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 20px;
    margin-left: 120px;
  }

  @media screen and (min-width: 1600px) {
    margin-top: 120px;
  }
}

.button {
  position: absolute;
  margin-top: 20px;
  margin-left: 10px;
  @media (--viewportMedium) {
    margin-left: 120px;
    margin-top: 60px;
    width: 276px;
    height: 65px;
  }

  @media screen and (min-width: 1000px) {
    margin-top: 160px;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 160px;
  }

  @media screen and (min-width: 1600px) {
    margin-top: 260px;
  }
}


.defaultButton {
  @apply --marketplaceButtonStyles;
  align-self: flex-end;
  min-height: auto;
  border-radius: 5px;
  font-weight: 900;
  font-size: 16px;
  padding: 10px;

  @media (--viewportMedium) {
    display: block;
    font-size: 30px;
    padding: 8px 20px;
    width: 200px;
    min-height: 55px;
  }

  @media (--viewportLarge) {
    width: 200px;
  }

  @media screen and (min-width: 1200px) {
    width: auto;
  }
}

.yellowText {
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 21px;
  color: var(--marketplaceSecondaryColor);
  @media (--viewportMedium){
  }
}

.whiteText {
  font-size: 20px;
  display: none;
  @media (--viewportMedium){
    font-size: 27px;
    display: block;
  }
  color: var(--matterColorLight);
  margin-bottom: 0;
}

.whiteTextMobile{
  display: block;

  @media (--viewportMedium){
    display: none;
  }
}

.darkText {
  margin-top: 0px;
  color: var(--marketplaceColorDark);
  font-weight: 900;

}

.darkTextMaxWidth {
  font-size: 20px;
  @media (--viewportMedium){
    font-size: 27px;
  }
  color: var(--marketplaceColorDark);
  max-width: 720px;
}

.lightText {
  color: var(--marketplaceColor);
  margin-top: 0px;
  font-weight: 900;
}

.backgroundWhite {
  background-color: var(--matterColorLight);
}

.backgroundDark {
  background-color: var(--marketplaceColorDark);
}

.emptyBackgroundWhite {
  width: 100%;
  height: 500px;
  background-color: var(--matterColorLight);
}

.emptyBackgroundDark {
  width: 100%;
  height: 350px;
  background-color: var(--marketplaceColorDark);
}


.backgroundLight {
  background-color: var(--marketplaceColor);
}

.tipsAndTricksContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.whenYouDriveContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (--viewportMedium) {
    margin-top: 60px;
  }
}

.whenYouHostContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 86px;
  justify-content: space-between;
  /* @media (--viewportMedium) {
    flex-direction: row;
  } */
}

.whenYouDriveItemContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  
  @media (--viewportMedium) {
    align-items: flex-start;

    flex-direction: row;
    margin-bottom: 64px;
  }
}

.whenYouDriveItemContainer p {
  margin-bottom: 40px;
  line-height: 28px;
  @media (--viewportLarge){
    font-size: 27px;
    line-height: 34px;
    margin-top: 0;
  }
}
.whenYouDriveItemTitleContainer{
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  @media (--viewportMedium){
    margin-bottom: 0px;
    width: auto;
  }
  flex-direction: row;
  display: flex;
  align-items: flex-start;
}

.whenYouDriveItemTextBox {
  max-width: 337px;

  @media (--viewportMedium) {

  }
}

.whenYouDriveItemTextBox p{
  margin-top: 0px;
}


.whenYouDriveItem {
  color: var(--matterColorLight);
  max-width: 100%;

  @media (--viewportLarge) {
    max-width: 46%;
  }

  @media (--viewportMLarge) {
    max-width: 512px;
  }
}

.whenYouHostItem {
  width: 50%;
  color: var(--matterColorLight);
  text-align: center;
  @media (--viewportMedium) {
    max-width: 48%;
  }

  @media (--viewportLarge) {
    max-width: 180px;

    &:last-child{
      max-width: 200px;
      padding-right: 0px;
    }
  }

  @media (--viewportMLarge) {
    max-width: 200px;

    &:last-child{
      max-width: 240px;
      padding-right: 0px;
    }
  }
}

.whenYouHostImage {
  width: auto; 
  height: 100px;

  @media (--viewportMedium){
    height: 111px;
  }
  @media (--viewportMLarge) {
    height: 185px;
  }
}

.whenYouHostItemText{
  font-size: 18px;
  margin-top: 24px;
  font-weight: 700;
  max-width: 152px;

  @media (--viewportMedium){
    font-size: 24px;
    text-align: center;
    margin: 36px auto;
    max-width: 200px;
  }

  @media (--viewportMLarge){
    font-size: 27px;
    max-width: 240px;
  }
}

.tipsAndTricksItem {
  color: var(--marketplaceColorDark);
  margin-bottom: 20px;

  &:last-child {
    border-bottom: solid 1px #000000;
    padding-bottom: 12px;
    @media (--viewportMedium){
      border-bottom-color: transparent;
    }
  }

  @media (--viewportMedium) {
    flex-basis: 48%;
  }

  @media (--viewportMLarge) {
    text-align: center;
    flex-basis: 240px;
  }
}

.tipsAndTricksItem  span {
  margin-bottom: 15px;
  font-weight: 700;
  margin-top: 14px;
  margin-bottom: 0px;
  font-size: 24px;
  line-height: 30px;
  display: block;
  min-height: 58px;
  @media (--viewportMedium){
    margin-top: 0px;
  }
}

.tipsAndTricksItem  p {
  line-height: normal;
  font-size: 18px;
  margin: 0px;
  @media (--viewportMedium){
    max-width: 100%;
  }
}

.divider{
  width: 60%;
  border-bottom: solid 1px #000;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: none;

  @media (--viewportMedium){
    /* display: block; */
  }
}

.whenYouDriveItemImage {
  width: 48px;
  height: 48px;
  margin-right: 24px;

  @media (--viewportMedium) {
    width: 110px;
    height: auto;
    margin-right: 42px;
  }
}

.whenYouDriveItemTitle{
  font-size: 25px;
  font-weight: 700;
  line-height: normal;
  display: none;
  
  @media (--viewportMedium){
    max-width: 200px;
    display: block;
    font-size: 20px;
  }

  @media (--viewportLarge) {
    font-size: 27px;
    line-height: 34px;
    max-width: 100%;
    font-size: 25px;
  }
}

.whenYouDriveItemTitleMobile{
  display: block;
  font-weight: 900;
  
  @media (--viewportMedium){
    display: none;
  }
}


.someTips{
  text-align: center;
  @media (--viewportMedium){
    text-align: left;
  }
}

.newlineMobile {

  @media (--viewportMedium) {
    display: none;
  }
}


.safeNtucImage{
  max-height: 136px;
  max-width: 200px;
  margin: auto;

  @media (--viewportMedium) {
    margin-left: 25px;
  }
}

.safeFirstImageContainer{
  @media (--viewportLarge){
    display: flex;
    align-items: flex-end;
  }
}

.driveItemImageSecondSource{
  margin-top: 25px;
  height: 50px;
  background-color: white;
  padding: 2px;
}


.staticPageWrapper h2, .staticPageWrapper h3, .staticPageWrapper h4, .staticPageWrapper h5 {
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  letter-spacing: normal;
  margin-top: 0;
  margin-bottom: 24px;
  font-weight: 900;
}